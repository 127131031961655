<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-card flat class="card_action">
      <v-card-actions>
        <v-row justify="end">
          <v-col>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Wyszukaj"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col style="text-align: right">
            <v-btn
              color="secondary_dark"
              dark
              depressed
              tile
              to="/pakiety-kategorie"
              v-if="$store.state.user_permissions.package_category"
            >
              Kategorie pakietów
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    
    <v-card>
      <v-data-table
        class="classic_table"
        :headers="headers"
        :items="list"
        :search="search"
        item-key="name"
        @click:row="rowClick"
        :footer-props="{
          itemsPerPageText : 'Elementów na stronie',
          itemsPerPageAllText : 'Wszystkie',
          itemsPerPageOptions : [15,45,90,-1],
        }"
      >
        <template v-slot:no-data>Brak danych</template>
        <template v-slot:no-results>Brak wyników</template>
        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }}-{{ pageStop }} z {{ itemsLength}}
        </template>
        <template
          v-slot:item.package="{ item }"
        >
          <v-btn
            v-for="(pack,index) in item.packages"
            :key="index"
            :color="pack.color"
            outlined
            style="margin: 5px"
          >{{pack.name}}</v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista Klientów - pakiety',
        disabled: true,
        to: '/pakiety',
      },
    ],

    headers: [
      {
        text: 'Nazwa Klienta',
        align: 'start',
        value: 'name',
      },
      { text: 'Pakiety', value: 'package' },
    ],

    list: [
      {
        id: 1,
        done: false,
        name: 'Nazwa Klienta',
        finished_at: 'Nazwa Klienta',
        full_name: 'Pełna nazwa',
        contact_person: 'Imię Nazwisko',
        nip: '14012021',
        number: '12313131233'
      },
    ],
    
    search: ''
  }),
  methods: {
    rowClick(value) {
      this.$router.push('/pakiet/'+value.id);
    }
  },
  mounted(){
    this.$store.commit('loader');
    this.$axios({url: this.$store.state.api +'/package_user', data: {}, method: 'GET' })
      .then(resp => {
        this.list = resp.data;
        console.log(resp.data);
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
.classic_table {
  margin-top: 25px;
}
.paid {
  background-color: green;
  color: white;
  padding: 5px 10px;
  text-align: center;
}
.not_paid {
  background-color: red;
  color: white;
  padding: 5px;
  text-align: center;
}
</style>